<template>
  <div>
    <div class="count">
      <span class="title">{{ text }}</span>
      <span class="content" v-if="maxCount">{{ parsedCount }} / {{ parsedMaxCount }}</span>
      <span class="content" v-else>{{ parsedCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Count",
  props: {
    text: {type: String, default: 'Total'},
    count: {type: Number, default: 0},
    maxCount: Number,
  },
  computed: {
    parsedCount() {
      if (this.count >= 0) {
        return this.count.toLocaleString();
      }
      return 0;
    },

    parsedMaxCount() {
      if (this.maxCount && this.maxCount >= 0) {
        return this.maxCount.toLocaleString();
      }
      return 0;
    }
  }
}
</script>

<style scoped lang="scss">
.count {
  @extend %listing-total;
  min-width: 50px;
}
</style>